<template>
  <div id="app">
    <el-container>
      <el-header id="app-header" height="80px">
        <el-menu id="top-menu" mode="horizontal" :router="true" :default-active="activeLink">
          <div class="logo">
            <img :src="logo" alt="Suisse Optical" />
          </div>
          <el-menu-item index="/">Configuratore</el-menu-item>

          <!-- Guest menu -->
          <el-menu-item v-show="userIsGuest" @click="loginDialogVisible = true">Login</el-menu-item>

          <!-- User menu -->
          <el-menu-item v-show="!userIsGuest" @click="loginDialogVisible = true">Account</el-menu-item>
          <el-menu-item v-show="!userIsGuest" index="/carrello">
            <el-badge :value="rowNumber">Carrello</el-badge>
          </el-menu-item>
          <el-menu-item v-show="!userIsGuest" index="/storico">Storico ordini</el-menu-item>
          <el-menu-item index="/store-locator">Store Locator</el-menu-item>
        </el-menu>
      </el-header>
      <el-container>
        <router-view />
      </el-container>
    </el-container>
    <login-dialog :visible="loginDialogVisible" @close="loginDialogVisible = false" />
    <div v-if="showPopUp" class="popup">
      <div class="container-popup">
        <div class="text-popup">
          <h1>ATTENZIONE</h1>
          <p>
            {{
              $t("message.A_causa_del_potenziamento_dei_nostri_sistemi,_garantiamo_l*evasione_degli_ordini_effettuati_entro_il_12_dicembre**_Tutti_gli_ordini_ricevuti_successivamente,_saranno_presi_in_carico_senza_alcuna_omissione**_Le_spedizioni_riprenderanno_regolarmente_dal_07/01,_quando_torneremo_alla_piena_operatività_con_un_sistema_ancora_più_efficiente,_pensato_per_servirti_il_meglio**")
            }} </p>
        </div>
        <div class="button-popup">
          <div class="checkbox-popup">
            <label>
              <input type="checkbox" v-model="dontShowAgain" /> Non mostrare più
            </label>
          </div>
          <div class="buttons">
            <button @click="closePopUp">Chiudi</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isInDateRange" class="button-alert-popup uppercase" @click="changePopUp">
      <div>{{ $t("message.Avviso") }} !</div>
    </div>
  </div>
</template>

<script>
import LoginDialog from "./components/LoginDialog";
import { mapGetters, mapActions } from "vuex";
import Logo from "./assets/images/S-O_younique.png";

export default {
  data: function () {
    return {
      loginDialogVisible: false,
      logo: Logo,
      activeLink: null,
      showPopUp: false,
      dontShowAgain: false,
      isInDateRange: false,
    };
  },
  components: {
    LoginDialog,
  },
  mounted: async function () {
    const dontShow = localStorage.getItem("dontShow");
    const startDate = new Date("2024-12-01"); // Inizio intervallo
    const endDate = new Date("2025-01-07"); // Fine intervallo
    const currentDate = new Date();
    this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    if (this.isInDateRange && (!dontShow || dontShow === "false")) {
      this.showPopUp = true;
    }
    await this.getUserData();
    this.activeLink = this.$route.path;
  },
  methods: {
    closePopUp() {
      // Salva la preferenza dell'utente nel localStorage
      if (this.dontShowAgain) {
        localStorage.setItem("dontShow", "true");
      }
      this.showPopUp = false;
    },
    changePopUp() {
      this.showPopUp = !this.showPopUp; // Cambia lo stato del popup
    },
    ...mapActions("user", ["getUserData"]),
  },
  computed: {
    ...mapGetters({ userIsGuest: "user/isGuest" }),
    ...mapGetters("cart", ["rowNumber"]),
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.activeLink = to.path;
    },
  },
};
</script>

<style lang="less">
#app {
  font: normal normal 300 20px/24px Rubik;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .uppercase {
    text-transform: uppercase;
  }

  .popup {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 25%;
    background-color: black;
    color: white;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);

    .container-popup {
      display: flex;
      gap: 0.5em;
      flex-direction: column;
      justify-content: space-between;

      .text-popup {
        h1 {
          margin: 0;
          color: red;
        }

        p {
          margin: 10px 0;
        }
      }

      .button-popup {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 2em;

        .checkbox-popup {
          cursor: pointer;

          label {
            color: white;
            font-size: 14px;
          }
        }

        .buttons {
          button {
            padding: 10px 20px;
            border: none;
            background-color: #e74c3c;
            color: white;
            font-size: 16px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s;
          }

          button:hover {
            background-color: #c0392b;
          }
        }
      }
    }
  }

  .button-alert-popup {
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    background-color: red;
    color: white;
    padding: 10px;
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1%;
    right: 50%;
    translate: 50%;
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  #app .popup {
    width: 80%;
    height: 60%;

    .container-popup {
      .text-popup {
        h1 {
          font-size: 1.5em;
        }

        p {
          font-size: 1em;
        }
      }

      .button-popup {
        flex-direction: column;
      }
    }

  }

  .button-alert-popup {
    translate: 50%;
  }


}

#app-header {
  padding: 0;

  #top-menu {
    background-color: #753bbd;
    height: 60px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;

    li {
      // font-size: 20px;
      height: 40px;
      line-height: 40px;
      margin-top: 10px;
    }

    .is-active {
      border: solid 2px;
      font-weight: 500;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      top: -20px;
      left: 5%;
      position: absolute;
      width: 100px;
      width: 133px;
      border: solid @--color-primary 1px;
      margin-top: 2px;

      img {
        max-width: 100%;
      }
    }
  }
}
</style>
