/* eslint-disable no-unused-vars */
import ajax from "../../plugins/ajax_request";
import _ from "lodash";

const state = () => ({ items: [], itemsSizes: [], colors: [] });

const mutations = {};

const getters = {
  checkErrors(state, getters, rootState) {
    let errors = [];
    if (_.isEmpty(rootState.frontale)) {
      errors.push({ label: "model", message: "Il frontale è obbligatorio" });
    }

    if (rootState.calibro == "" || rootState.naso == "") {
      errors.push({ label: "size", message: "La misura è obbligatoria" });
    }

    if (_.isEmpty(rootState.colore)) {
      errors.push({ label: "color", message: "Il colore è obbligatorio" });
    }

    if (_.isEmpty(rootState.finitura)) {
      errors.push({
        label: "finishing",
        message: "La finitura è obbligatoria",
      });
    }

    return errors;
  },
};

const actions = {
  async getItems({ state, commit, dispatch, mutations }) {
    let res = await ajax.getItems(),
      self = this;
    if (res.status >= 200 && res.status < 300) {
      state.items = res.data;

      // Set a default random frontal
      const defaultModel = _.find(state.items, ((f) => {
        return f.ModelName == 'G3-306';
      }));
      commit("setFrontal", defaultModel, { root: true });
    }
  },
  async getAllModelsSizes({ state }) {
    let res = await ajax.getAllModelsSizes();
    if (res.status >= 200 && res.status < 300) {
      state.itemsSizes = res.data;
    }
  },
  async getColors({ state, commit, dispatch, mutations }) {
    let res = await ajax.getColors();
    if (res.status >= 200 && res.status < 300) {
      state.colors = res.data;

      // Set a default color
      const defaultColor = _.find(state.colors, ((c) => {
        return c.U_THE_COLORE.indexOf('PL0619') >= 0;
      }))
      commit("setColore", defaultColor, { root: true });
    }
  },

  // getModelRandom({ state, commit }) {
  //   let rnd = _.random(state.items.length);
  //   commit("setFrontal", state.items[rnd], { root: true });
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
